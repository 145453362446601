import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, finalize } from 'rxjs';
import { PropertyDetails } from '../models/api-models/property-details';
import { LoaderService } from './loader.service';
import { SnackBarService } from './snack-bar.service';
import { SupplierImagesService } from './supplier-images.service';
import { Review } from '../models/api-models/review';
import { HttpClient } from '@angular/common/http';
import { hosts } from '../constants/hosts';
import { extractErrors } from '../utils/utils';
import { ISuggestion } from '../models/api-models/suggestion';

export interface IStarCounts {
  stars5: number;
  stars4: number;
  stars3: number;
  stars2: number;
  stars1: number;
}

@Injectable({
  providedIn: 'root'
})
export class PropertyService {
  private _dataLoading$ = new BehaviorSubject<boolean>(true);
  public get dataLoading$(): Observable<boolean> { return this._dataLoading$.asObservable(); }

  private _reviewsLoading$ = new BehaviorSubject<boolean>(true);
  public get reviewsLoading$(): Observable<boolean> { return this._reviewsLoading$.asObservable(); }

  private _haveError$ = new BehaviorSubject<boolean>(false);
  public get haveError$(): Observable<boolean> { return this._haveError$.asObservable(); }

  private _property$ = new BehaviorSubject<PropertyDetails | null>(null);
  public get property$(): Observable<PropertyDetails | null> { return this._property$.asObservable(); }

  private _reviews$ = new BehaviorSubject<Review[]>([]);
  public get reviews$(): Observable<Review[]> { return this._reviews$.asObservable(); }

  private _overallReviews$ = new BehaviorSubject<Review | null>(null);
  public get overallReviews$(): Observable<Review | null> { return this._overallReviews$.asObservable(); }

  private _starCounts$ = new BehaviorSubject<IStarCounts | null>(null);
  public get starCounts$(): Observable<IStarCounts | null> { return this._starCounts$.asObservable(); }

  private _suggestionsLoading$ = new BehaviorSubject<boolean>(false);
  public get suggestionsLoading$(): Observable<boolean> { return this._suggestionsLoading$.asObservable(); }

  private _currentSuggestions$ = new BehaviorSubject<ISuggestion[]>([]);
  public get currentSuggestions$(): Observable<ISuggestion[]> { return this._currentSuggestions$; }

  private _currentPropertyId: number | null = null;

  constructor(
    private http: HttpClient,
    private loader: LoaderService,
    private snackBar: SnackBarService,
    private supplierImages: SupplierImagesService
  ) {
  }

  public getSearchSuggestions(input: string): void {
    this._suggestionsLoading$.next(true);

    this.http.get<ISuggestion[]>(encodeURI(`${hosts.distributionProperty}/property/auto_suggest?city=${input}`))
      .pipe(
        catchError(err => {
          this.snackBar.showError(`Error getting auto suggestions: ${extractErrors(err)}`);
          throw err;
        }),
        finalize(() => {
          this._suggestionsLoading$.next(false);
        })).subscribe(value => this._currentSuggestions$.next(value));
  }

  public getPropertyDetails(propertyId: number) {
    if (this._currentPropertyId === propertyId && this._property$.value) {
      return;
    }

    this._currentPropertyId = propertyId;

    this.loader.show();
    this._dataLoading$.next(true);

    this.getPropertyReviews(propertyId);

    this.http.get(`${hosts.distributionProperty}/property/${propertyId}/details`)
      .pipe(
        catchError(err => {
          this.snackBar.showError(`Error getting property details: ${extractErrors(err)}`);

          this._haveError$.next(true);

          throw err;
        }),
        finalize(() => {
          this.loader.hide();
          this._dataLoading$.next(false);
        }))
      .subscribe((details: any) => {
        const prop = new PropertyDetails(details);

        this._property$.next(prop);
        this.supplierImages.getSupplierImages(prop.supplier!.id!);
      });
  }

  private getPropertyReviews(propertyId: number) {
    this._reviewsLoading$.next(true);

    this.http.get(`${hosts.distributionProperty}/property/${propertyId}/reviews`)
      .pipe(
        catchError(err => {
          this.snackBar.showError(`Error getting property reviews: ${extractErrors(err)}`);

          this._haveError$.next(true);

          throw err;
        }),
        finalize(() => this._reviewsLoading$.next(false)))
      .subscribe((reviews: any) => {
        const propReviews: Review[] = reviews.map((item: any) => new Review(item));
        propReviews.sort((a, b) => {
          const dateA = a.updatedAt ?? a.createdAt;
          const dateB = b.updatedAt ?? b.createdAt;

          return dateB.diff(dateA);
        });

        this.computeGeneralReview(propReviews);
        this._reviews$.next(propReviews);
      });
  }

  private computeGeneralReview(reviews: Review[]) {
    if (!reviews?.length) {
      this._overallReviews$.next(null);
      this._starCounts$.next(null);

      return;
    }

    const general: Review = new Review({});
    const starCounts: IStarCounts = {
      stars1: 0,
      stars2: 0,
      stars3: 0,
      stars4: 0,
      stars5: 0
    };

    const scoreKeys = Object.keys(general).filter(item => item.endsWith('Score'));
    const scoreCounts: { [key: string]: number } = {};

    for (const item of reviews) {
      for (const key of scoreKeys) {
        const reviewValue = (item as any)[key];
        const overallReviewValue = (general as any)[key];

        if (reviewValue != null) {
          (general as any)[key] = overallReviewValue == null ? reviewValue : overallReviewValue + reviewValue;
          scoreCounts[key] = scoreCounts[key] == null ? 1 : ++scoreCounts[key];
        }
      }

      if (item.overallScore != null) {
        starCounts[this.getStarKey(item.overallScore)] += 1;
      }
    }

    for (const key of scoreKeys) {
      (general as any)[key] = scoreCounts[key] ? Math.round(((general as any)[key] / scoreCounts[key]) * 10) / 10 : null;
    }

    this._overallReviews$.next(general);
    this._starCounts$.next(starCounts);
  }

  private getStarKey(overallValue: number): 'stars5' | 'stars4' | 'stars3' | 'stars2' | 'stars1' {
    if (overallValue === 5) {
      return 'stars5';
    } else if (overallValue >= 4) {
      return 'stars4';
    } else if (overallValue >= 3) {
      return 'stars3';
    } else if (overallValue >= 2) {
      return 'stars2';
    } else {
      return 'stars1';
    }
  }

}
