import dayjs from "dayjs";
import { alias } from "../../utils/utils";

export class Review {
    @alias('id')
    public id!: number;

    public createdAt!: dayjs.Dayjs;
    public updatedAt: dayjs.Dayjs | null = null;

    @alias('review_id')
    public reviewId?: string;

    @alias('review_created_at')
    public reviewCreatedAt!: string;

    @alias('review_headline')
    public reviewHeadline?: string;

    @alias('positive_content')
    public positiveContent?: string;

    @alias('negative_content')
    public negativeContent?: string;

    @alias('reviewer')
    public reviewer?: string;

    @alias('response')
    public response?: string;

    @alias('replied_at')
    public repliedAt?: string;



    @alias('overall_score')
    public overallScore?: number | null;

    @alias('clean_score')
    public cleanScore?: number | null;

    @alias('facilities_score')
    public facilitiesScore?: number | null;

    @alias('location_score')
    public locationScore?: number | null;

    @alias('services_score')
    public servicesScore?: number | null;

    @alias('staff_score')
    public staffScore?: number | null;

    public valueForMoneyScore?: number | null;

    constructor(args: any) {
        if (!args) {
            return;
        }

        const fields: { [key: string]: string } = (<any>Review)._alias;

        for (const field in fields) {
            if (Object.hasOwn(fields, field)) {
                const _alias = fields[field];
                (this as any)[field] = args[_alias];
                if ((this as any)[field] === undefined) {
                    (this as any)[field] = args[field];
                }
            }
        }

        this.createdAt = dayjs(args.created_at, 'YYYY-MM-DD');
        this.updatedAt = args.updated_at ? dayjs(args.updated_at, 'YYYY-MM-DD') : null;

        this.valueForMoneyScore = args.value_for_money ?? undefined;
    }
}

